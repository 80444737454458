<script setup lang="ts">
	import Dropdown from "primevue/dropdown";
	import { ref } from "vue";
	import { useRoute } from "vue-router"; // Importa useRoute

	const route = useRoute(); // Usa useRoute per accedere alle informazioni della route

	const countries = ref([
		{ name: "Italiano", code: "it" },
		{ name: "English", code: "en" },
		{ name: "Deutsch", code: "de" },
		{ name: "Nederlands", code: "nl" },
	]);

	const changeLocale = (locale: string) => {
		// localeObj as object;
		// primevue.config.locale = localeObj["it"];
		// console.log(localeObj["it"]);
		// console.log("changed");
	};
</script>

<template>
	<div
		class="surface-overlay py-3 px-6 shadow-2 flex w-full align-items-center justify-content-between relative lg:sticky top-0"
		style="min-height: 80px; z-index: 999"
	>
		<ApplicationLogo class="block h-10 w-auto fill-current text-gray-600" />
		<div
			v-ripple
			class="cursor-pointer block lg:hidden text-700 mt-1 p-ripple"
			v-styleclass="{
				selector: '@next',
				enterClass: 'hidden',
				leaveToClass: 'hidden',
				hideOnOutsideClick: true,
			}"
		>
			<i class="pi pi-bars text-4xl"></i>
		</div>
		<div
			class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full surface-overlay left-0 top-100 z-1 shadow-2 lg:shadow-none"
		>
			<ul
				class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row"
			>
				<li>
					<NuxtLink
						:to="{ name: 'index___' + $i18n.locale }"
						:class="{
							'text-primary font-bold':
								route.name === 'index___' + $i18n.locale, // Usa route.name
							'hover:text-900 hover:surface-100 font-medium text-600':
								route.name !== 'index___' + $i18n.locale,
						}"
						class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center no-underline text-lg border-round cursor-pointer transition-colors transition-duration-150 p-ripple"
					>
						<i class="pi pi-home mr-2"></i>
						<span>
							{{ $t("navigation.header.home") }}
						</span>
					</NuxtLink>
				</li>
				<li>
					<NuxtLink
						:to="{ name: 'our-scooters___' + $i18n.locale }"
						:class="{
							'text-primary font-bold':
								route.name === 'our-scooters___' + $i18n.locale, // Usa route.name
							'hover:text-900 hover:surface-100 font-medium text-600':
								route.name !== 'our-scooters___' + $i18n.locale,
						}"
						class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center no-underline text-lg border-round cursor-pointer transition-colors transition-duration-150 p-ripple"
					>
						<i class="pi pi-book mr-2"></i>
						<span>
							{{ $t("navigation.header.ourMotorcycles") }}
						</span>
					</NuxtLink>
				</li>
				<li>
					<NuxtLink
						:to="{ name: 'company___' + $i18n.locale }"
						:class="{
							'text-primary font-bold':
								route.name === 'company___' + $i18n.locale, // Usa route.name
							'hover:text-900 hover:surface-100 font-medium text-600':
								route.name !== 'company___' + $i18n.locale,
						}"
						class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center no-underline text-lg border-round cursor-pointer transition-colors transition-duration-150 p-ripple"
					>
						<i class="pi pi-book mr-2"></i>
						<span>
							{{ $t("navigation.header.company") }}
						</span>
					</NuxtLink>
				</li>
				<li>
					<NuxtLink
						:to="{ name: 'who-we-are___' + $i18n.locale }"
						:class="{
							'text-primary font-bold':
								route.name === 'who-we-are___' + $i18n.locale, // Usa route.name
							'hover:text-900 hover:surface-100 font-medium text-600':
								route.name !== 'who-we-are___' + $i18n.locale,
						}"
						class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center no-underline text-lg border-round cursor-pointer transition-colors transition-duration-150 p-ripple"
					>
						<i class="pi pi-id-card mr-2"></i>
						<span> {{ $t("navigation.header.aboutUs") }} </span>
					</NuxtLink>
				</li>
			</ul>
			<ul
				class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row border-top-1 surface-border lg:border-top-none"
			>
				<li class="border-top-1 surface-border lg:border-top-none">
					<Dropdown
						:model-value="countries.find((lang) => lang.code === $i18n.locale)"
						:options="countries"
						optionLabel="name"
						optionValue="code"
						class="w-full md:w-14rem"
						aria-label="Select a Language"
						@update:model-value="
							$i18n.setLocale($event);
							changeLocale($event);
						"
					>
						<template #value="slotProps">
							<div v-if="slotProps.value" class="flex align-items-center">
								<nuxt-img
									:alt="'Language ' + slotProps.value.name"
									:src="`/images/flags/${slotProps.value.code}.png`"
									width="30"
									height="20"
									format="webp"
									quality="80"
									class="mr-2"
									priority
								/>
								<!-- <img
									:alt="slotProps.value.label"
									:src="`/images/flags/${slotProps.value.code}.png`"
									class="mr-2"
									style="width: 30px"
								/> -->
								<div>{{ slotProps.value.name }}</div>
							</div>
							<span v-else>
								{{ slotProps.placeholder }}
							</span>
						</template>
						<template #option="slotProps">
							<div class="flex align-items-center">
								<img
									:alt="slotProps.option.label"
									:src="`/images/flags/${slotProps.option.code}.png`"
									class="mr-2"
									style="width: 30px"
								/>
								<div>{{ slotProps.option.name }}</div>
							</div>
						</template>
					</Dropdown>
				</li>
			</ul>
		</div>
	</div>
</template>
